import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
  root: {
    placeSelf: "center",
    marginBottom: "2rem",
    [theme.breakpoints.up("xs")]: {
      minWidth: 300,
      maxWidth: 345,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 300,
      maxWidth: 500,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 300,
      maxWidth: 345,
      gridColumnStart: 1,
      gridRowStart: 2,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 300,
      maxWidth: 345,
      gridRowStart: 1,
      gridColumnStart: 3,
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 300,
      maxWidth: 345,
      gridRowStart: 1,
      gridColumnStart: 3,
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded] = React.useState(false);


  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
            <WhatsAppIcon/>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title="+55 67 99627-0476"
        // subheader="Estamos a disposição para melhor lhe atender"
      />
      <CardMedia
        className={classes.media}
        image="https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        title="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Solicite o seu orçamento ou tire suas dúvidas através do nosso correio eletrônico:
          <br/><br/>
          <b>+55 67 99627-0476</b>
          <br/><br/>
          Precisa falar conosco de forma rápida e fácil? Então o Whatsapp é a melhor opção!
          Com essa ferramenta, você pode entrar em contato conosco de forma simples e rápida, sem precisar esperar por um e-mail ou telefonema.
          Adicione nosso número de Whatsapp +55 67 99627-0476 e fale diretamente com nossa equipe. Estamos sempre prontos para ajudar e responder suas perguntas.
          Não hesite em entrar em contato conosco pelo Whatsapp sempre que precisar de nossa ajuda ou tiver alguma dúvida sobre nossos serviços.
          <br/>
          Estamos ansiosos para conversar com você em breve!
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions> */}
    </Card>
  );
}
