import { createTheme } from '@material-ui/core/styles';

const pink = '#f8bbd0';
const salmon = '#5a0000';

const theme = createTheme({
  props: {
    color: "black"
  //   space_up: {
  //     // border: "1px red solid",
  //     marginTop: "7rem", // eslint-disable-next-line
  //     [theme.breakpoints.up("xs")]: {
  //         marginTop: "3.3rem",
  //     }, // eslint-disable-next-line
  //     [theme.breakpoints.up("sm")]: {
  //         marginTop: "3.8rem",
  //     }, // eslint-disable-next-line
  //     [theme.breakpoints.up("md")]: {
  //         marginTop: "7rem",
  //     }, // eslint-disable-next-line
  //     [theme.breakpoints.up("lg")]: {
  //         marginTop: "7rem",
  //     }, // eslint-disable-next-line
  //     [theme.breakpoints.up("xl")]: {
  //         marginTop: "7rem",
  //     }, // eslint-disable-next-line
  //     marginBottom: "0.5rem"
  // },
  },
  palette: {
    primary: {
      main: `${pink}`,
    },
    secondary: {
      main: `${salmon}`,
    },
  },
});

export default theme
