// Libraries
import React from "react";
import { AppBar, Toolbar, useScrollTrigger} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";


// Icons and Logos
import Logo from '../../assets/logo.svg'
import LogoSM from '../../assets/logo_sm.png'
import ContactsIcon from '@material-ui/icons/Contacts';
import InfoIcon from '@material-ui/icons/Info';
import ExploreIcon from '@material-ui/icons/Explore';
import HouseIcon from '@material-ui/icons/House';



function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
const useStyles = makeStyles(theme => ({
    toolbarMargin: {
        height: "7rem",
        [theme.breakpoints.up("xs")]: {
            height: "3.3rem",
            border: "solid red 10px"
        },
        [theme.breakpoints.up("sm")]: {
            height: "3.8rem",
        },
        [theme.breakpoints.up("md")]: {
            height: "7rem",
        },
        [theme.breakpoints.up("lg")]: {
            height: "7rem",
        },
        [theme.breakpoints.up("xl")]: {
            height: "7rem",
        }
    },
    logo: {
        height: "7em",
        [theme.breakpoints.down("sm")]: {
            height: "3em"
        }
    },
    header: {
        background: 'rgba(255, 255, 255, 0.9)'

    },
    tabs: {
        width: "100%",
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    icon: {
        [theme.breakpoints.up("xs")]: {
            // transform: "scale(0.5)",
            // border: "solid red 1px"

        },
        [theme.breakpoints.up("sm")]: {

        },
        [theme.breakpoints.up("md")]: {

        },
        [theme.breakpoints.up("lg")]: {

        },
        [theme.breakpoints.up("xl")]: {

        }
    }
}))

const tabsOptions = [
    {
        icon: <HouseIcon/>,
        label: "Home",
        path: "/"
    },
    {
        icon: <ExploreIcon/>,
        label: "Portfólio",
        path: "/portfolio"
    },
    {
        icon: <ContactsIcon/>,
        label: "Contatos",
        path: "/contatos"
    },
    {
        icon: <InfoIcon/>,
        label: "Sobre",
        path: "/sobre"
    }
]

function Header(){
    const theme = useTheme();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const mediaDesktop = useMediaQuery(theme.breakpoints.up("md"))
    const mediaMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <React.Fragment>
            <ElevationScroll>
                <AppBar position="fixed" className={classes.header}>
                    <Toolbar disableGutters>
                            {(mediaDesktop && <img alt="company logo" src={Logo} className={classes.logo}/>) ||
                             (mediaMobile && <img alt="company logo" src={LogoSM} className={classes.logo}/>)
                            }

                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="secondary"
                                variant="fullWidth"
                                aria-label="Menu Options"
                                className={classes.tabs}
                            >
                                    {
                                        tabsOptions.map( (option, i) => (
                                            (mediaDesktop && <Tab icon={option.icon} component={Link} to={option.path} label={option.label}/>) ||
                                            (mediaMobile && <Tab className={classes.icon} icon={option.icon} component={Link} to={option.path}/>)

                                        ))
                                    }
                            </Tabs>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </React.Fragment>
    );

}

export default Header;
