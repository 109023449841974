import Carousel from '../components/ui/Carousel'
import LongCard from '../components/ui/LongCard'
// import CardHome from '../components/ui/CardHome'

const styleRoot = {
    display: "flex",
    flexDirection: "column"
}

const Home = function () {
    return (
        <div style={styleRoot}>
            <Carousel/>
            <LongCard text="… transformando simples traços em grandes sonhos!"/>
            {/* <CardHome textLeft={firstCard.textLeft} textRight={firstCard.textRight} style={firstCard.style}/>
            <CardHome textLeft={"Card2"} textRight={firstCard.textRight} style={{backgroundColor: "pink"}}/>
            <CardHome textLeft={"Card3"} textRight={firstCard.textRight} style={{backgroundColor: "yellow"}}/> */}
        </div>
    )
}

export default Home;
