import CardEmail from '../components/ui/CardEmail'
import CardInsta from '../components/ui/CardInsta'
import CardWhats from '../components/ui/CardWhats'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up("xs")]: {
            minHeight: "30rem",
            padding: "2rem",
            display: "grid",
          },
          [theme.breakpoints.up("sm")]: {
            minHeight: "30rem",
            padding: "2rem",
            display: "grid",
          },
          [theme.breakpoints.up("md")]: {
            minHeight: "30rem",
            padding: "2rem",
            display: "grid",

          },
          [theme.breakpoints.up("lg")]: {
            minHeight: "65rem",
            padding: "2rem",
            display: "grid",
          },
          [theme.breakpoints.up("xl")]: {
            minHeight: "80rem",
            padding: "2rem",
            display: "grid",
          }
    }
  }));

const Contatos = function (props) {
    const classes = useStyles()
    return (
        <div className={props.pageStyle+" "+classes.container}>
            <CardEmail/>
            <CardInsta/>
            <CardWhats/>

        </div>
    )
}

export default Contatos;
