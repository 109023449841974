import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import TelegramIcon from '@material-ui/icons/Telegram';

const useStyles = makeStyles(theme => ({
    footer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: "100%",
        minHeight: "5rem",
        alignItems: "center",
        justifyContent: "center"
    },
    link: {
        color: "black",
        fontFamily: "Arial",
        fontSize: "0.75rem",
        fontWeight: "bold"
    }
}));
const Footer = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.footer} spacing="2">
            <Grid item spacing="2">
                <Grid container spacing="2">
                    <Grid item><a href="https://www.instagram.com/arq_leticia_hokari/" target="_blank"><InstagramIcon/></a></Grid>
                    <Grid item><a href="https://api.whatsapp.com/send/?phone=556781150933&text=Ol%C3%A1%2C+vim+pelo+Instagram+e+gostaria+de+saber+sobre..&app_absent=0" target="_blank"><WhatsAppIcon /></a></Grid>
                    {/* <Grid item><TelegramIcon /></Grid> */}
                </Grid>
            </Grid>
            <Grid item className={classes.link}>
                Todos os direitos reservados por LH Arquitetura 2024.
            </Grid>
        </Grid>
    )
}

export default Footer;
