import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "20rem",
    width: "100%",
    // border: '1px solid red'
  },
  text: {
    fontSize: "3rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.5rem"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.7rem"
      // backgroundColor: "red"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem"
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "3rem"
    },
    // border: '1px solid',
    padding: '1rem',
    fontFamily: "Manrope,sans-serif"
  }
}));

export default function LongCard(props) {
  const classes = useStyles()
  return(
    // container
    <Grid className={classes.root} container   justifyContent="center"   alignItems="center"   direction="row" style={props.style} >
      <Grid item className={classes.text}>
            {props.text}
      </Grid>
    </Grid>
  )
}
