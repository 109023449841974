import './App.css';
import Header from './components/ui/Header';
import { ThemeProvider } from '@material-ui/styles';
import UiTheme from './components/ui/Theme'
import { Route, Routes, BrowserRouter } from "react-router-dom"
import Home from './pages/Home'
import Portfolio from './pages/Portfolio'
import Sobre from './pages/Sobre'
import Contatos from './pages/Contatos'
import Footer from './components/ui/Footer';
import {makeStyles } from '@material-ui/core';
// import { useTheme } from '@material-ui/core';
// import Image from './images/lh_arquitetura.jpeg';


const useStyles = makeStyles(theme => ({
  container: {
    display: "block",
    backgroundColor:"#f5f5f5",
    minHeight: "7em",
    [theme.breakpoints.up("xs")]: {
      minHeight: "40rem"
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "57rem"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "40rem"
      // backgroundColor: "red"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "40rem"
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "40rem"
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageMargin: {
    marginTop: "7.5rem",
    [theme.breakpoints.up("xs")]: {
        marginTop: "3.8rem",
    },
    [theme.breakpoints.up("sm")]: {
        marginTop: "3.13rem",
    },
    [theme.breakpoints.up("md")]: {
        marginTop: "7.5rem",
    },
    [theme.breakpoints.up("lg")]: {
        marginTop: "7.5rem",
    },
    [theme.breakpoints.up("xl")]: {
        marginTop: "7.5rem",
    },
    marginBottom: "0.5rem"
  }
}))
function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={UiTheme}>
        <BrowserRouter>
          <Header/>
          <Routes>
            <Route path='/' element={<div className={classes.container}><Home/></div>}/>
            <Route path='/portfolio' element={<div className={classes.container}><Portfolio pageStyle={classes.pageMargin}/></div>}/>
            <Route path='/contatos' element={<div className={classes.container}><Contatos pageStyle={classes.pageMargin}/></div>}/>
            <Route path='/sobre' element={<div className={classes.container}><Sobre pageStyle={classes.pageMargin}/></div>}/>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
