import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("xs")]: {
            width: "100%",
            height: "auto",
          },
          [theme.breakpoints.up("sm")]: {
          },
          [theme.breakpoints.up("md")]: {

          },
          [theme.breakpoints.up("lg")]: {
          },
          [theme.breakpoints.up("xl")]: {
          }
    },
    imageRoot: {
        [theme.breakpoints.up("xs")]: {
            width: "100%",
            height: "auto",
          },
          [theme.breakpoints.up("sm")]: {
          },
          [theme.breakpoints.up("md")]: {

          },
          [theme.breakpoints.up("lg")]: {
          },
          [theme.breakpoints.up("xl")]: {
          }
    }
  }));

const ImageAbout = function() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <img className={classes.imageRoot} src="https://lharquitetura.com/images/assets/about.jpeg"/>
        </div>
    )
}

export default ImageAbout
