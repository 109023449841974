import ImageAbout from "../components/ui/ImageAbout"
import CardAbout from "../components/ui/CardAbout"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up("xs")]: {
            minHeight: "68rem",
            padding: "2rem",
            display: "grid",
          },
          [theme.breakpoints.up("sm")]: {
          },
          [theme.breakpoints.up("md")]: {

          },
          [theme.breakpoints.up("lg")]: {
          },
          [theme.breakpoints.up("xl")]: {
          }
    }
  }));


const Home = function (props) {
    const classes = useStyles()
    return (
        <div className={props.pageStyle+" "+classes.container}>
            <CardAbout/>
            <ImageAbout/>
        </div>
    )
}

export default Home;
