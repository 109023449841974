import Galery from '../components/ui/Gallery';

const Home = function (props) {
    return (
        <div className={props.pageStyle}>
            <Galery/>
        </div>
    )
}

export default Home;
