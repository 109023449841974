import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState, useEffect } from 'react';



const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const images_array = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath: require('../../assets/carousel/1.jpeg')
  },
  {
    label: 'Bird',
    imgPath: require('../../assets/carousel/2.jpeg'),
  },
  {
    label: 'Bali, Indonesia',
    imgPath: require('../../assets/carousel/3.png'),
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath: require('../../assets/carousel/4.png'),
  }
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#000000",
    color: "#ffffff"
  },
  header: {
    display: 'block',
    alignItems: 'center',
    width: "100%",
    height: "auto",
    paddingLeft: theme.spacing(4),
    backgroundColor: "#000000",
    color: "#ffffff"
  },
  img: {
    display: "block",
    height: "100%",
    width: "100%",
    backgroundColor: "#000000",
    color: "#ffffff",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    backgroundColor: "#000000",
    color: "#ffffff"      
  }
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images_array.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images_array.map((step, index) => (
          <div key={step.label} st>
            {Math.abs(activeStep - index) <= 2 ? (
              <div style={{height: windowDimensions.height, overflow: "hidden"}}>
          
                <div className={classes.img} style={{ backgroundImage: `url(${step.imgPath.default})` }}/>
                {console.log({ backgroundImage: `url(${step.imgPath.default})` })}
              </div>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button className={classes.stepper} size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button className={classes.stepper} size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
}

export default SwipeableTextMobileStepper;
