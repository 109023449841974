import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  root:
  {
    placeSelf: "center",
    [theme.breakpoints.up("xs")]: {
      minWidth: 300,
      maxWidth: 345,
      marginBottom: "2rem",

    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 300,
      maxWidth: 500,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 300,
      maxWidth: 345,
      gridRowStart: 1,
      gridColumnStart: 1,
      gridColumnEnd: 2,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 300,
      maxWidth: 345,
      gridRowStart: 1,
      gridColumnStart: 1
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 300,
      maxWidth: 345,
      gridRowStart: 1,
      gridColumnStart: 1
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded] = React.useState(false);


  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
            <EmailIcon/>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title="contact@lharquitetura.com"
        // subheader="Estamos a disposição para melhor lhe atender"
      />
      <CardMedia
        className={classes.media}
        image="https://images.pexels.com/photos/8091228/pexels-photo-8091228.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        title="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Solicite o seu orçamento ou tire suas dúvidas através do nosso correio eletrônico:
          <br/><br/>
          <b>leticiahokari.arquitetura@gmail.com</b>
          <br/><br/>
          Se você está interessado(a) em nossos serviços de arquitetura ou tem alguma dúvida sobre nossos projetos, não hesite em entrar em contato conosco por e-mail.
          Estamos sempre disponíveis para responder suas perguntas e fornecer mais informações sobre nossos serviços. Além disso, se você estiver interessado(a) em agendar uma reunião conosco, podemos fazer isso por e-mail também.
          Aguardamos seu contato e esperamos poder ajudá-lo(a) a transformar seus sonhos em realidade.

Atenciosamente,
<br/>
LH Arquitetura
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions> */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
            minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
