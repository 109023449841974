import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Projetos Inovadores
        </Typography>
        <Typography variant="h5" component="h2">
          Sobre a LH Arquitetura
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Foco nos detalhes e no cliente.
        </Typography>
        <Typography variant="body2" component="p">
        Somos um escritório de arquitetura fundado em 2019 pela arquiteta Leticia Hokari. Nosso foco é criar projetos com designs belos e detalhados, que atendam às necessidades e desejos dos nossos clientes.

Acreditamos que a arquitetura deve ser mais do que simplesmente construir um espaço funcional. Nós nos esforçamos para criar ambientes que inspirem e enriqueçam a vida das pessoas que os utilizam. Para isso, utilizamos materiais de alta qualidade e tecnologia de ponta, combinados com um olhar apurado para o design e a estética.

Nosso trabalho é realizado com paixão e dedicação, sempre buscando a excelência em cada detalhe. Estamos comprometidos em criar projetos únicos e personalizados para cada cliente, levando em conta suas necessidades, estilo de vida e orçamento.

Se você está procurando um escritório de arquitetura que se preocupa com a qualidade e a beleza do design, entre em contato conosco. Estamos prontos para ajudá-lo a transformar seus sonhos em realidade.
        </Typography>
      </CardContent>
    </Card>
  );
}
